<template>
  <section id="inspiration">
    <div id="mainContent" class="w-full wrapper">
      <div class="intro text-center pb-10">
        <h1 class="text-white pb-5">Inspiration</h1>
        <!-- <button class="background-grey"></button> -->
        <div
          class="menu w-full block m-auto text-center py-10 lg:grid-cols-4 xs:grid-cols-2 gap-2"
          :class="{ affirmationActive: affirmationActive, songsActive: songsActive, readingsActive: readingsActive }"
        >
          <button @click.prevent="showDesc('')" :class="{ active: allOff }">ALL</button>
          <button
            @click.prevent="showDesc('affirmation')"
            @mouseover="affirmationHover = true"
            @mouseleave="affirmationHover = false"
            :class="{ active: affirmationActive }"
          >
            AFFIRMATIONS
          </button>
          <button
            @click.prevent="showDesc('songs')"
            @mouseover="songsHover = true"
            @mouseleave="songsHover = false"
            :class="{ active: songsActive }"
          >
            MUSIC
          </button>
          <button
            @click.prevent="showDesc('readings')"
            @mouseover="readingsHover = true"
            @mouseleave="readingsHover = false"
            :class="{ active: readingsActive }"
          >
            READINGS
          </button>
        </div>
        <div class="overview w-full max-w-xl">
          <p
            class=""
            v-show="allOff && !affirmationHover && !songsHover && !readingsHover"
            v-html="pageData.Content"
          ></p>
          <p class="italic" v-show="affirmationHover" v-html="pageData.AffirmationsDescription"></p>
          <p class="italic" v-show="songsHover" v-html="pageData.SongsDescription"></p>
          <p class="italic" v-show="readingsHover" v-html="pageData.PassagesDescription"></p>
        </div>
      </div>
      <div class="mb-24" v-show="affirmationActive || allOff">
        <h2 class="pb-10">Affirmations</h2>
        <div class="affirmations">
          <vue-horizontal responsive scroll :button="true" class="horizontal" :displacement="0.7">
            <article
              v-for="(item, index) in affirmations"
              :key="item.id"
              class="tile flex items-center content-center text-center px-8"
              :class="`grad${index % 13}`"
            >
              <a class="block" data-type="affirmation" :data-ref="index" @click="modalClick">{{
                checkLength(item.Title, 80)
              }}</a>
            </article>
          </vue-horizontal>
        </div>
      </div>
      <div class="mb-24" v-show="songsActive || allOff">
        <h2 class="pb-10">Music</h2>
        <div class="music">
          <vue-horizontal responsive scroll :button="true" class="horizontal" :displacement="0.7">
            <article
              v-for="(item, index) in songs"
              :key="item.id"
              class="tile flex items-center content-center text-center px-8"
            >
              <a data-type="block video" :data-ref="index" @click="modalClick"
                ><h4>{{ checkLength(item.Title, 80) }}</h4>
                <p>{{ checkLength(item.Byline, 80) }}</p></a
              >
            </article>
          </vue-horizontal>
        </div>
      </div>
      <div class="mb-24" v-show="readingsActive || allOff">
        <h2 class="pb-10">Readings</h2>
        <div class="readings">
          <vue-horizontal responsive scroll :button="true" class="horizontal" :displacement="0.7">
            <article
              v-for="(item, index) in readings"
              :key="item.id"
              class="tile flex items-center content-center text-center px-8"
            >
              <a data-type="block readings" :data-ref="index" @click="modalClick"
                ><h4>{{ checkLength(item.Title, 80) }}</h4>
                <p>{{ checkLength(item.Byline, 80) }}</p></a
              >
            </article>
          </vue-horizontal>
        </div>
      </div>
    </div>
    <!--Modal-->
    <Modal
      v-if="showModal"
      @close="showModal = false"
      :class="modalProps.type == 'affirmation' ? `affirmation grad${modalProps.gradient}` : ''"
    >
      <span slot="header" v-html="modalProps.title"></span>
      <div slot="body" class="inline-block" v-html="modalProps.content"></div>
    </Modal>
  </section>
</template>

<script>
import axios from "axios";
import "tailwindcss/tailwind.css";
import Modal from "../components/modal.vue";

export default {
  components: { Modal },
  data() {
    return {
      token: "",
      loaded: false,
      loggedIn: false,
      pageData: {},
      affirmationHover: false,
      songsHover: false,
      readingsHover: false,
      affirmationActive: false,
      songsActive: false,
      readingsActive: false,
      affirmations: [],
      songs: [],
      readings: [],
      showModal: false,
      modalProps: {},
    };
  },
  mounted() {
    this.checkSession();
    if (!this.loaded) {
      this.loadContent();
      this.loaded = true;
    }
    // this.$store.watch(
    //   (state) => {
    //     return state.user.authenticated;
    //   },
    //   ((newVal, oldVal) => {
    //     if (newVal !== oldVal) {
    //       //   this.$forceUpdate();
    //     }
    //   }).bind(this),
    //   {}
    // );
  },
  computed: {
    allOff() {
      return !this.affirmationActive && !this.songsActive && !this.readingsActive;
    },
  },
  methods: {
    showDesc: function(category) {
      switch (category) {
        case "affirmation":
          this.affirmationActive = true;
          this.songsActive = false;
          this.readingsActive = false;
          break;
        case "songs":
          this.affirmationActive = false;
          this.songsActive = true;
          this.readingsActive = false;

          break;
        case "readings":
          this.affirmationActive = false;
          this.songsActive = false;
          this.readingsActive = true;
          break;
        default:
          this.affirmationActive = false;
          this.songsActive = false;
          this.readingsActive = false;
          break;
      }
    },
    checkLength: function(content, length) {
      return content.length > length ? `${content.substring(0, length)}...` : content;
    },
    checkSession: function() {
      var tokenAvail = window.localStorage.length;
      if (tokenAvail == 0) {
        return;
      } else {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
          this.token = userToken;
        }
      }
    },
    stripHTML: function(str) {
      var div = document.createElement("div");
      div.innerHTML = str;
      return div.textContent || div.innerText || "";
    },
    callApi: function(path, secureFlag, callback) {
      if (secureFlag) {
        axios
          .get(this.$store.state.api + path, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          })
          .then(callback)
          .catch((error) => console.log(error));
      } else {
        axios
          .get(this.$store.state.api + path)
          .then(callback)
          .catch((error) => console.log(error));
      }
    },
    loadContent: function() {
      this.callApi("inspiration-page/", false, (response) => {
        this.pageData = response.data;
      });
      this.callApi("inspirations/", false, (response) => {
        this.affirmations = response.data;
        this.callApi("songs/", false, (response) => {
          this.songs = response.data;
          this.callApi("passages/", false, (response) => {
            // console.log(response.data);
            this.readings = response.data;
          });
        });
      });
      //
      // 'inspirations/'
    },
    modalClick: function(e) {
      // var modalHTML = e.target.getAttribute('href');
      var modalContent, modalData, title;

      switch (e.target.dataset.type) {
        case "video":
          modalData = this.songs[e.target.dataset.ref];
          var playerVars = {
            autoplay: 1,
            controls: 1,
            enablejsapi: 1,
            modestbranding: 0,
            playsinline: 1,
            rel: 0,
            origin: window.location.origin,
          };
          var vidID = modalData.SongURL.split("v=")[1];
          var playerVarStr = Object.entries(playerVars)
            .map((pair) => pair.join("="))
            .join("&");
          modalContent = `<div id="vframe"><iframe width="100%" height="100%" style="position: absolute; left: 0; top: 0; width: 100%; height: 100%" src="https://www.youtube.com/embed/${vidID}?${playerVarStr}"></iframe></div>`;
          title = modalData.Title;
          break;
        case "readings":
          modalData = this.readings[e.target.dataset.ref];
          title = modalData.Title;
          modalContent = `<div class="readings w-full lg:max-h-screen max-w-half md:w-4/5 mx-auto lg:flex md:block lg:items-center md:items-top"><div class="serif lg:px-15 md:px-15 leading-loose pb-20 w-full text-${
            modalData.Alignment
          } ${modalData.Alignment == "left" ? "px-30" : ""}">${modalData.Content}</div></div>`;
          break;
        case "affirmation":
          modalData = this.affirmations[e.target.dataset.ref];
          title = modalData.showOverlayTitle == true ? modalData.Title : "";
          // title = modalData.Title;
          modalContent = `<div class="passage w-full lg:max-h-screen max-w-half mx-auto lg:flex md:block lg:items-center md:items-top"><div class="serif text-center text-white">${modalData.Content}</div></div>`;
          break;
      }
      var newModal = {
        gradient: e.target.dataset.ref,
        type: e.target.dataset.type,
        title: title,
        content: modalContent,
        active: true,
      };
      this.modalProps = newModal;
      this.showModal = true;
    },
  },
};
</script>
<style lang="scss" scope>
@import "../styles/gradients";
@import "../styles/typovars";
@import "../styles/shared";
.serif {
  font-family: $serif;
}
$headerOffset: 136px;
section {
  position: relative;
  color: #fff;
}
button {
  color: #fff;
  background-color: rgba(16, 31, 57,.2);
  font-size: 90%;
  padding: 1em 0 0.9em;
  &.active {
    background-color: $yellow;
  }
}
.overview {
  margin: auto;
  min-height: 110px;
}
#inspiration {
  background: $blue;

  min-height: calc(100vh);

  #mainContent {
    margin: auto;
    // margin-top: $headerOffset;
    padding: 136px 52px 60px 44px;
    h2 {
      padding-bottom: 18px;
      color: $yellow;
    }
    .grid {
      padding-bottom: 60px;
    }
    .tile {
      min-width: calc(25% - 20px);
      max-width: calc(40% - 20px);
      margin-right: 40px;
      min-height: 200px;
      border-radius: 4px;
      margin-bottom: 0px;

      a {
        margin: auto;
        text-decoration: none;
        font-weight: 600;
        font-size: 1.75em;
        line-height: 1em;
        font-family: $serif;
      }
      p {
        margin-bottom: 0px;
      }
      @media (hover: hover) {
        transition: all 0.45s ease;
        &:hover {
          background: rgba(16, 31, 57, 1);
        }
      }
      @media only screen and (max-width: $minwidth) {
        width: calc(55% - 10px);
        min-width: initial;
        max-width: initial;
        margin-right: 20px;
      }
    }
    .music {
      .tile {
        background: radial-gradient(circle, rgba(16, 31, 57, 0) 0%, rgba(16, 31, 57, 0.85) 100%);
        @media (hover: hover) {
          transition: all 0.45s ease;
          &:hover {
            background: radial-gradient(circle, rgba(16, 31, 57, 1) 0%, rgba(16, 31, 57, 1) 100%);
          }
        }
      }
    }
    .readings {
      .tile {
        background: rgba(16, 31, 57, 0.35);
        @media (hover: hover) {
          transition: all 0.45s ease;
          &:hover {
            background: rgba(16, 31, 57, 1);
          }
        }
      }
    }
    .readings {
      @include fluid-type($minwidth, $maxwidth, 16px, 20px);
    }

    @media only screen and (max-width: $minwidth) {
      height: initial;
      margin-top: -116px;
      padding: 136px 52px 60px 44px;
      max-width: 100vw;
    }
  }
  .vue-horizontal {
    .v-hl-btn {
      top: -15px;
    }
    /* width */
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      transition: height 0.45s ease-out;
    }
    ::-webkit-scrollbar:hover {
      height: 6px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: $blue;
      border-radius: 50%;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(16, 31, 57, 0.3);
      border-radius: 10px;
      transition: background-color 0.45s ease-out;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(16, 31, 57, 1);
    }
    .v-hl-container {
      padding-bottom: 15px;
    }
  }
}
.modal {
  &.affirmation {
    .modal-header {
      color: #efefef;
    }
    .modal-overlay {
      display: none;
    }
    .serif {
      h1 {
        // font-weight: 800;
        font-style: normal;
        color: #efefef;
        font-family: $serif;
        font-size: calc(64 / 1920 * 100vw);
        line-height: 1.125em;
      }
      h2 {
        font-family: $serif;
        color: #efefef;
        // font-weight: 700;
        font-style: normal;
        line-height: 1em;
      }
    }
  }

  .readings {
    .leading-loose {
      p {
        line-height: 2;
      }
    }
  }
}
</style>
